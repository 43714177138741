<template>
  <v-container v-if="hasPermission" fluid>
    <mex-heading content="Release Notes" />
    <mex-sperm-spinner v-if="releaseNotesLoading" :spinnerText="releaseNotesLoadingText" />
    <template v-else>
      <v-row justify="center" class="mt-8">
        <v-col cols="11">
          <vue-markdown class="markdown_container" :html="true" :source="releaseNotes"></vue-markdown>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import requiredPermissions from "../requiredPermissions";
import VueMarkdown from "vue-markdown/src/VueMarkdown";
import SystemSettingsService from "../services/systemSettings.service";

export default {
  name: "ReleaseNotes",
  components: {
    VueMarkdown
  },
  data() {
    return {
      // Visualization
      releaseNotesLoading: true,
      releaseNotesLoadingText: "Release notes loading",
      hasPermission: false,
      // Content
      releaseNotes: ""
    };
  },
  created() {
    this.releaseNotesLoading = true;
    if (this.isLoggedIn) {
      this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.keyTEXAll, this.$store)
        .then((hasPermission) => {
          if (hasPermission) {
            this.hasPermission = true;
            this.getReleaseNotes();
          } else {
            this.$router.push({ name: "NotFound" });
          }
        })
        .catch(() => {
          this.$router.push({ name: "NotFound" });
        });
    }
  },
  computed: {
    ...mapGetters("sysAuth", ["isLoggedIn", "getUser", "getUserPermissions"])
  },
  methods: {
    getReleaseNotes() {
      this.releaseNotesLoading = true;
      SystemSettingsService.getReleaseNotes()
        .then(response => {
          this.releaseNotesLoading = false;
          this.releaseNotes = response.data;
        })
        .catch(err => {
          this.releaseNotesLoading = false;
          this.$toast.error("Error while fetching the release notes: " + err);
        });
    }
  }
};
</script>

<style>
.markdown_container {
  font-size: 14px;
  padding: 30px 100px 30px 100px;
  border-radius: 5px;
  background: var(--v-background-base);
  : blue;
  box-shadow: inset 10px 10px 30px  var(--v-primary-base),
  inset -10px -10px 30px var(--v-primary-base);
}

.markdown_container a {
  color: var(--v-primaryAccent-base);;
}
</style>
