import httpClient from '../httpClient/httpClient';

const SystemSettingsService = {
  getSystemSettings() {
    return httpClient.get('api/get-system-settings', {});
  },
  getSystemSettingIdleLimit() {
    return httpClient.get('api/get-system-setting-idle-limit', {});
  },
  updateSystemSettings(systemSettings) {
    return httpClient.post('api/update-system-settings', { ...systemSettings });
  },
  getReleaseNotes() {
    return httpClient.get('api/get-release-notes', {});
  }
};

export default SystemSettingsService;
